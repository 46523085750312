@tailwind base;
@tailwind components;
@tailwind utilities;

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@import './styles/variables.scss';

@import './custom-theme.scss';

html,
body {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input,
textarea,
select {
  --tw-ring-shadow: 0 0 #000 !important;
}

.hiddenCanvasElement {
  display: none !important;
}

/* Estilos compartidos generados con Tailwind CSS */
@layer components {
  /**Botones**/
  .btn-with-icon {
    @apply cursor-pointer inline-flex border-2 border-transparent hover:bg-slate-200 focus:border-ica-primary;
  }

  .ic-btn {
    @apply inline-flex max-h-9 items-center justify-center rounded-3xl border-2 border-transparent p-2 text-sm font-normal text-ica-textsidebar shadow-sm focus:outline-none enabled:hover:bg-white enabled:hover:text-ica-backgroung disabled:cursor-none disabled:bg-zinc-500 disabled:opacity-60 md:text-base;
  }

  .form-input {
    @apply mt-1 block rounded-full border-gray-300 px-4 py-2 focus:border-ica-primary focus:ring focus:ring-ica-primary focus:ring-opacity-50;
  }

  .ic-btn-primary {
    @apply ic-btn bg-ica-primary enabled:hover:border-ica-primary;
  }

  .ic-btn-error-red {
    @apply ic-btn bg-[#dc2626] enabled:hover:border-[#dc2626];
  }

  .ic-btn-secondary {
    @apply ic-btn bg-ica-secondary enabled:hover:border-ica-secondary;
  }

  .ic-btn-third {
    @apply ic-btn bg-ica-third enabled:hover:border-ica-third;
  }

  .ic-btn-date {
    @apply flex cursor-pointer items-center text-xl font-medium text-ica-secondary;
  }

  .ic-btn-secondaryv2 {
    @apply mb-2 me-2 flex items-center rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-ica-primary focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700;
  }
  /** Textos y etiquetas ***/
  .li-menu {
    @apply inline-flex items-center text-lg font-medium text-ica-textsidebar hover:text-ica-primary;
  }

  .li-menu-module {
    @apply li-menu font-medium;
  }

  .ic-label-normal {
    @apply font-normal md:text-base;
  }

  .ic-label-primary {
    @apply text-sm font-bold leading-7 text-ica-third md:text-base;
  }

  .ic-label-primary-xl {
    @apply text-lg font-extrabold leading-6 text-ica-third md:text-xl;
  }

  .ic-label-secondary {
    @apply text-sm font-normal leading-6 text-ica-third md:text-base;
  }

  .ic-links {
    @apply ml-1 flex cursor-pointer items-center font-extra-bold text-ica-primary hover:text-green-500;
  }

  /** elementos de busqueda para la tabla **/
  .ic-input-search {
    @apply block w-full rounded-3xl border border-gray-300 py-[0.3rem] pl-10 text-base text-ica-backgroung focus:border-ica-primary focus:ring-0 md:py-[0.4rem];
  }

  .ic-input-search-table {
    @apply block w-full rounded-l-3xl border border-gray-300 py-[0.4rem] pl-10 text-lg font-semibold text-ica-backgroung focus:border-ica-primary focus:ring-0 md:py-[0.4rem];
  }

  .ic-button-search {
    @apply inline-flex max-w-[88px] cursor-pointer items-center rounded-r-3xl border border-ica-primary bg-ica-primary px-2 py-[0.3rem] text-base font-medium text-white md:py-[0.4rem] disabled:cursor-none disabled:bg-zinc-500 disabled:opacity-60;
  }

  .ic-btn-delete {
    @apply inline-flex cursor-pointer items-center rounded-full bg-red-600 p-1 text-sm text-white;
  }

  .ic-action-delete {
    @apply ic-btn mx-2 bg-red-600 p-1 enabled:hover:border-ica-primary;
  }

  .ic-action-edit {
    @apply ic-btn mx-2 bg-ica-primary p-1 enabled:hover:border-ica-primary;
  }

  .ic-action-third {
    @apply ic-btn mx-2 bg-ica-third p-1 enabled:hover:border-ica-third;
  }

  .ic-action-down {
    @apply ic-btn mx-2 bg-red-500 p-1 enabled:hover:bg-red-500;
  }

  .ic-action-custom {
    @apply ic-btn mx-2 p-1 enabled:hover:border-ica-third;
  }

  /**Etiquetas con un tamaño en especifico**/

  .ic-label-1 {
    @apply text-2xl font-bold;
  }

  .ic-label-2 {
    @apply text-3xl font-black;
  }

  .ic-label-encabezado {
    @apply text-3xl font-bold leading-8 text-ica-primary;
  }

  .ic-label-subtitle {
    @apply text-xl font-bold leading-8 text-ica-primary;
  }

  .error-forms-input {
    @apply ml-6 text-sm font-medium text-red-500;
  }

  .input-is-invalid {
    @apply border border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500;
  }

  .item_card__counters {
    @apply break-words text-lg font-semibold text-ica-primary cursor-pointer hover:scale-110 p-2  rounded-xl bg-white shadow-2xl border;

    &_active {
      @apply bg-ica-primary text-white underline shadow-inner;
    }
  }

  .table_td_custom_red {
    @apply text-red-500 font-bold;
  }

  .table_td_custom_blue {
    @apply text-blue-600 font-bold;
  }

  .table_td_custom_yellow {
    @apply text-yellow-600 font-bold;
  }

  .chip_custom {
    @apply rounded-md border border-transparent px-2.5 py-0.5 text-sm text-white shadow-sm transition-all cursor-none;
  }
  .chip_custom_red {
    @apply bg-red-500 chip_custom;
  }


  .chip_custom_blue {
    @apply bg-blue-600 chip_custom;
  }

  .slot_asignado {
    @apply bg-green-700 cursor-not-allowed;
  }

  .slot_ocupado {
    @apply bg-red-500 cursor-not-allowed;
  }

  .slot_vacio {
    @apply bg-blue-600 hover:bg-blue-800;
  }
}


mat-icon {
  padding: 0 !important;
  margin: 0 !important;
}

/* Styles for tab labels */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.  ------ REVISAR AQUI*/
.mat-tab-label,
.mat-tab-label.mat-tab-label-active {
  min-width: 25px !important;
  padding: 5px;
  background-color: transparent;
  color: $ica-primary;
  font-weight: 700 !important;
  font-size: 16px !important;
}

/* Styles for the ink bar */
.mat-ink-bar {
  background-color: $ica-primary !important;
}

/* Styles para la barra de desplazamiento */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(112, 190, 1, 0.5);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, 1);
}

/* Agrega un efecto hover a la barra de desplazamiento */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(112, 190, 1, 0.8);
}


/**Validos para los iconos**/
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-32 { font-size: 32px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }
.material-icons.ic-icon.md-inactive { color: rgba(0, 0, 0, 0.26); }
.material-icons.ic-icon { color: #5bc504; background-color: transparent; }
.material-icons.ic-icon-red { color: #dc2626; background-color: transparent; }

:host ::ng-deep .p-scrollpanel {
  /*   p {
      padding: .5rem;
      line-height: 1.5;
      margin: 0;
  }
 */
  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--layer-1);
    }

    .p-scrollpanel-bar {
      background-color: #9de660;
      opacity: 1;
      transition: background-color 0.2s;
      top: calc(0%);

      &:hover {
        background-color: #5bc500;
      }
    }
  }
}

/**FIX STYLE Angular material 15 with Tailwind **/
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}


.mdc-tex-field {
  .mdc-text-field__input {
    padding: 0.5rem;
  }
}

